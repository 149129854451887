@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arial:wght@400..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Arial", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 1px;
}

.logo {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
}

.status-todos .gantt_task_content {
    background-color: #dbeafe !important;
    color: #1e40af !important;
}
  
.status-inProgress .gantt_task_content {
    background-color: #fef9c3 !important;
    color: #854d0e !important;
}
  
.status-inReview .gantt_task_content {
    background-color: #f3e8ff !important;
    color: #6b21a8 !important;
}
  
.status-completed .gantt_task_content {
    background-color: #dcfce7 !important;
    color: #166534 !important;
}

.status-phase .gantt_task_content {
    background-color: #f3f4f6 !important;
    color: #1f2937 !important;
}

.gantt_grid_scale .gantt_grid_head_cell {
    border-color: white !important;
    border: 0 !important;
}

.gantt_scale_line .gantt_scale_cell {
    border-color: white !important;
    border: 0 !important;
    background-color: #f3f4f6 !important;
}

.gantt_layout_cell {
    border-radius: 6px !important;
}

/* The container for your marker */
.gantt_marker.today_marker {
    /* By default, .gantt_marker has some top padding, but we can override or let it be */
    background: #333;
}
  
.gantt_marker.today_marker .gantt_marker_line {
    background-color: #333; 
    width: 2px;            
}
  
.gantt_marker.today_marker .gantt_marker_content {
    background-color: #333 !important; 
    color: #fff !important;           
    border-radius: 6px;               
    padding: 4px 8px;                 
    white-space: nowrap;    
    transform: translateX(-50%);
}  
